.img{
    height: 250px;
    cursor: pointer;
    transition: 300ms;
    transition-delay: 500ms;
}

.img:hover{
    height: 550px
}
body{
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #202020;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(53, 51, 51);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(44, 44, 44);
  }